import { mmcAjax } from '@/common/js/mmcModule';
const baseurl = 'https://api.jisuchou.net/';

const checkConfirmList = function (projuuid) {
    const url = `${baseurl}mmc/project/confirm/check/${projuuid}`;
    return mmcAjax({
        method: 'GET',
        url,
        crossDomain: true,
    })
}

const createConfirmList = function (params) {
    const url = `${baseurl}mmc/project/confirm/create`;
    return mmcAjax({
        method: 'POST',
        url,
        data: {
            'uuid': params.projuuid,
            'relation': params.relation,
            'name': params.name,
            'id_card': params.id_card,
            'content': params.content,
        },
        crossDomain: true,
    })
}


export {
    checkConfirmList,
    createConfirmList
}
