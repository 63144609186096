<template>
  <div class="prove_wrap" :class="certification ? 'heigh': ''" v-if="loadding">
      <p class="dec">
          感谢您的热心，如果您参与、探访、深入了解过此求助项目，希望您如实填写以下真实身份、真实情况，这将帮ta赢取更多信任。
      </p>
      <div class="item relation">
          <p class="sub_title">您的身份</p>
          <input type="text" readonly @click="showSheet" placeholder="请选择" class="input_item" v-model="relation">
          <span class="err" v-if="errIcon.relation">选择您的身份</span>
      </div>
      <div class="item" v-if="!certification">
          <p class="sub_title">姓名</p>
          <input type="text" @blur="nameCheck" placeholder="填写真实姓名" class="input_item" v-model="name">
          <span class="err" v-if="errIcon.name">请填写姓名</span>
      </div>
      <div class="item" v-if="!certification">
          <p class="sub_title">身份证号</p>
          <input type="text"  @blur="idCardCheck" placeholder="填写您的身份证号" class="input_item" v-model="id_card">
          <span class="err" v-if="errIcon.id_card">请填写身份证号</span>
      </div>
      <div class="item">
          <p class="sub_title">证实内容</p>
          <textarea placeholder="请介绍一些详细的情况(10个字以上)" v-model="content" class="text_item" cols="30" rows="3"></textarea>
          <span class="err" v-if="errIcon.content">请填写证实内容</span>
      </div>
      <div class="foot_btn" @click="createConfirm">帮TA证实</div>
      <van-popup class="popWrap beParant" round position="bottom"  :style="{ height: '300px' }"  v-model="popShow" >
          <van-picker
              title="请选择您的身份"
              show-toolbar
              :columns="action"
              @confirm="onConfirm"
              @cancel="onCancel"
              @change="onChange"
          />
      </van-popup>
  </div>
</template>
<script>
import { Picker, Popup } from 'vant';
import { authChecker } from '@/common/js/mmcModule';
import { shareProj } from '@/common/js/mmcModule';
import  { checkConfirmList, createConfirmList } from './api'
export default {
    name: 'index',
    components: {
        vanPicker: Picker,
        vanPopup: Popup
    },
    data() {
        return {
            popShow: false,
            relation: '',
            relationVal: '',
            name: '',
            id_card: '',
            content: '',
            action: [
                
                { text: '朋友',value: 1 },
                { text: '邻居',value: 2 },
                { text: '同事',value: 3 },
                { text: '亲戚',value: 4 },
                { text: '医生',value: 5 },
                { text: '其他',value: 6 }
            ],
            errIcon: {
                relation: false,
                name: false,
                id_card: false,
                content: false
            },
            certification: false,
            confirm: false,
            loadding: false
        }
    },
    created() {
        const that = this;
        showLoading('加载中...');
        authChecker.check({ notneedphone: 0 }, (res) => {
            that.initDate();
            const { projuuid } = that.$route.query;
            const shareParams = {
                projuuid
            };
            shareProj.init(shareParams);
        });
    },
    methods: {
        onConfirm(e) {
            this.errIcon.relation = false;
            this.popShow = false;
            this.relation = e.text;
            this.relationVal = e.value;
        },
        onCancel() {
            this.popShow = false;
        },
        onChange(e) {

        },
        initDate() {
            const { projuuid } = this.$route.query;

            const that = this;
            checkConfirmList(projuuid).then(res => {
                if(res.code === 0 ) {
                    const { certification, confirm } = res.data;
                    that.certification = certification;
                    hideLoading();
                    that.loadding = true;
                    that.confirm = confirm;
                    if(confirm) {
                        alertMsg('该项目已证实');
                        setTimeout(()=> {
                            history.go(-1);
                        },2000)
                    }
                }
            })
        },
        showSheet() {
            this.popShow = true;
        },
        createConfirm() {
            const { projuuid } = this.$route.query;
            const { relationVal, id_card, name, content } = this;
            if(!this.valueCheck()) {
                return;
            }
            showLoading('提交中...');
            createConfirmList({
                projuuid,
                id_card,
                name,
                content,
                relation: relationVal
            }).then(res => {
                hideLoading();
                if(res.code === 0){
                    alertMsg('证实成功');
                    setTimeout(() => {
                        location.replace(`${window.BasePath}fund/project/detail?projuuid=${projuuid}`)
                    }, 2000)
                }
            })
        },
        valueCheck() {
            const { relationVal, id_card, name, content } = this;
            if(!this.certification) { // 未进行实名认证
                if(relationVal == ''){
                    this.errIcon.relation = true;
                    alertMsg('请选择您的身份')
                    return false
                }else if(!name){
                    this.errIcon.name = true;
                    alertMsg('请填写姓名')
                    return false
                }else if(!id_card){
                    this.errIcon.id_card = true;
                    alertMsg('请填写正确的身份证号')
                    return false
                }else if(content.length < 10){
                    this.errIcon.content = true;
                    alertMsg('证实内容字数少于10')
                    return false
                }
            }else{ // 已实名
                if(relationVal == ''){
                    this.errIcon.relation = true;
                    alertMsg('请选择您的身份')
                    return false
                }else if(this.content.length < 10){
                    this.errIcon.id_card = true;
                    alertMsg('证实内容字数少于10')
                    return false
                }
            }
            return true;
        },
        nameCheck() {
          if(this.name === '') {
              this.errIcon.name = true;
          }else{
              this.errIcon.name = false;
          }
        },
        idCardCheck() {
            var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
            if(reg.test(this.id_card) === false) {
                this.errIcon.id_card = true;
                alertMsg('请输入正确的身份证号')
            }else{
                this.errIcon.id_card = false;
            }
        }
    }
}
</script>

<style scoped lang="less">
.prove_wrap {
    height: 667px;
    &.heigh {
        height: 367px;
    }
    .dec {
        border-radius: 4px;
        margin: 20px;
        padding: 10px;
        text-align: left;
        color: #FFA44E;
        line-height: 1.7;
        background: #F8F7F4;
    }
    .item {
        margin: 0 20px 25px;
        position: relative;
        &.relation {
            &:after {
                content: '';
                display: inline-block;
                width: 8px;
                height: 8px;
                border-bottom: 1px solid #999;
                border-left: 1px solid #999;
                transform: rotate(-135deg);
                position: absolute;
                bottom: 13px;
                right: 5px;
            }
        }
        .sub_title {
            color: #666;
            text-align: left;
            font-size: 17px;
            position: relative;
        }
        .err {
            color: red;
            float: right;
            margin-top: 5px;
        }
        .input_item {
            width: 100%;
            margin-top: 15px;
            border: none;
            border-bottom: 1px solid #e8e8e8;
            padding-bottom: 7px;
            &::-webkit-input-placeholder {
                color: #CDCED2;
            }
        }
        .text_item {
            width: 100%;
            margin-top: 15px;
            border: none;
            &::-webkit-input-placeholder {
                color: #CDCED2;
            }
        }
    }
    .foot_btn {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 50px;
        text-align: center;
        line-height: 50px;
        color: #fff;
        font-size: 17px;
        background-image: linear-gradient(90deg, #40B9FF 0%, #009BFF 100%);
        box-shadow: 0 4px 30px 0 rgba(0,155,255,0.40);
    }
    /deep/.van-picker__confirm {
        color: #019CFF;
    }
}
</style>
